var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('vue-data-table',{attrs:{"cols":[
      {
        header: 'Name',
        value: 'name',
      },
      {
        header: 'Key',
        value: 'key'
      },
      {
        header: 'Icon',
        value: 'icon'
      },
      {
        header: 'Required',
        value: 'required'
      },
      {
        header: 'Added At',
        value: 'created_at',
        sort: true
      },
      {
        header: 'Last Modified',
        value: 'updated_at',
        sort: true
      },
      {
        header: 'Actions',
        value: 'actions'
      }
    ],"path":"assemblies"},scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('vue-button',{attrs:{"icon":['fas', 'plus-circle']},on:{"click":function($event){return _vm.$router.push({ name: 'assemblies.new' })}}},[_vm._v(" Create ")])]},proxy:true},{key:"item.icon",fn:function(row){return [(row.icon)?_c('font-awesome-icon',{attrs:{"icon":['fas', row.icon],"title":row.icon,"aria-label":row.icon,"fixed-width":""}}):_vm._e()]}},{key:"item.required",fn:function(row){return [(row.required)?_c('font-awesome-icon',{staticClass:"text-green-700",attrs:{"icon":['fas', 'check'],"fixed-width":""}}):_c('font-awesome-icon',{staticClass:"text-red-700",attrs:{"icon":['fas', 'times'],"fixed-width":""}})]}},{key:"item.created_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.created_at))+" ")]}},{key:"item.updated_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.updated_at))+" ")]}},{key:"item.actions",fn:function(row){return [_c('vue-link',{staticClass:"inline-flex cursor-pointer",attrs:{"to":{
          name: 'assemblies.single',
          params: {
            model: row.id
          }
        },"icon":['fas', 'edit'],"aria-label":("Edit Assembly: " + (row.name)),"text":"","sm":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }